/* @import '~antd/dist/antd.css'; */

html,body,#root{
  height: 100%;
  width: 100%;
  font-family: '微软雅黑';
}
/* 滚动条优化 start */
::-webkit-scrollbar{
width:5px;
height:5px;
}
::-webkit-scrollbar-track{
background: #f6f6f6;
border-radius:2px;
}
::-webkit-scrollbar-thumb{
background: #cdcdcd;
border-radius:2px;
}
::-webkit-scrollbar-thumb:hover{
background: #747474;
}
::-webkit-scrollbar-corner {
background: #f6f6f6;
}